import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}));

type Props = {
  color?: 'primary' | 'secondary';
};

export function LoadingScreen({ color = 'primary' }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress color={color} />
    </div>
  );
}
