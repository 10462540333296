import { env } from './config/env';
import {
  CurrencyCode,
  LanguageCode,
  PromoCodeType,
} from './graphql/globalTypes';

export const PADDING_HEIGHT = 40;
export const ROW_HEIGHT = 50;

export const DEFAULT_CURRENCY_CODE = CurrencyCode.USD;

export const AK_URL = 'https://ancient-world-abercrombiekent.web.app';
export const AW_URL = env.IS_RTM
  ? 'https://ancient-world.co'
  : 'https://awa.lithodomos.com';

export const SUPPORTED_CURRENCIES = [
  CurrencyCode.USD,
  CurrencyCode.AUD,
  CurrencyCode.GBP,
  CurrencyCode.EUR,
];

// keep this consistent between I18nInfoField in types.ts
export const SUPPORTED_LANGUAGE_CODES = [
  LanguageCode.ar,
  LanguageCode.de_DE,
  LanguageCode.el_GR,
  LanguageCode.en_US,
  LanguageCode.es_ES,
  LanguageCode.fr_FR,
  LanguageCode.he_IL,
  LanguageCode.hi_IN,
  LanguageCode.ko_KR,
  LanguageCode.it_IT,
  LanguageCode.ja_JP,
  LanguageCode.pl_PL,
  LanguageCode.pt_PT,
  LanguageCode.tl_PH,
  LanguageCode.ru_RU,
  LanguageCode.zh,
];

export const DEFAULT_PLAYLIST_PRICE = {
  AUD: 0,
  BRL: 0,
  CAD: 0,
  CHF: 0,
  CNY: 0,
  CZK: 0,
  DKK: 0,
  EUR: 0,
  GBP: 0,
  HKD: 0,
  HUF: 0,
  ILS: 0,
  JPY: 0,
  MXN: 0,
  MYR: 0,
  NOK: 0,
  NZD: 0,
  PHP: 0,
  PLN: 0,
  RUB: 0,
  SEK: 0,
  SGD: 0,
  THB: 0,
  TWD: 0,
  USD: 0,
};

export const EXCHANGE_RATES = {
  [CurrencyCode.AUD]: 1.36,
  [CurrencyCode.BRL]: 5.2,
  [CurrencyCode.CAD]: 1.26,
  [CurrencyCode.CNY]: 6.48,
  [CurrencyCode.CZK]: 21.79,
  [CurrencyCode.DKK]: 6.31,
  [CurrencyCode.EUR]: 0.85,
  [CurrencyCode.HKD]: 7.77,
  [CurrencyCode.HUF]: 305.85,
  [CurrencyCode.ILS]: 3.27,
  [CurrencyCode.JPY]: 110.36,
  [CurrencyCode.MYR]: 4.23,
  [CurrencyCode.MXN]: 20.08,
  [CurrencyCode.TWD]: 28.08,
  [CurrencyCode.NZD]: 1.44,
  [CurrencyCode.NOK]: 8.88,
  [CurrencyCode.PHP]: 50.32,
  [CurrencyCode.PLN]: 3.89,
  [CurrencyCode.GBP]: 0.73,
  [CurrencyCode.RUB]: 73.94,
  [CurrencyCode.SGD]: 1.36,
  [CurrencyCode.SEK]: 8.69,
  [CurrencyCode.CHF]: 0.92,
  [CurrencyCode.THB]: 32.96,
  [CurrencyCode.USD]: 1,
};

export const TIERED_PRICES = {
  199: {
    [CurrencyCode.AUD]: 249,
    [CurrencyCode.BRL]: 1049,
    [CurrencyCode.CAD]: 249,
    [CurrencyCode.CNY]: 1299,
    [CurrencyCode.CZK]: 4499,
    [CurrencyCode.DKK]: 1249,
    [CurrencyCode.EUR]: 149,
    [CurrencyCode.HKD]: 1549,
    [CurrencyCode.HUF]: 59900,
    [CurrencyCode.ILS]: 649,
    [CurrencyCode.JPY]: 21900,
    [CurrencyCode.MYR]: 849,
    [CurrencyCode.MXN]: 3999,
    [CurrencyCode.TWD]: 5500,
    [CurrencyCode.NZD]: 299,
    [CurrencyCode.NOK]: 1749,
    [CurrencyCode.PHP]: 9999,
    [CurrencyCode.PLN]: 749,
    [CurrencyCode.GBP]: 149,
    [CurrencyCode.RUB]: 14999,
    [CurrencyCode.SGD]: 249,
    [CurrencyCode.SEK]: 1749,
    [CurrencyCode.CHF]: 199,
    [CurrencyCode.THB]: 6499,
    [CurrencyCode.USD]: 199,
  },
  349: {
    [CurrencyCode.AUD]: 449,
    [CurrencyCode.BRL]: 1799,
    [CurrencyCode.CAD]: 449,
    [CurrencyCode.CNY]: 2249,
    [CurrencyCode.CZK]: 7499,
    [CurrencyCode.DKK]: 2199,
    [CurrencyCode.EUR]: 299,
    [CurrencyCode.HKD]: 2699,
    [CurrencyCode.HUF]: 99900,
    [CurrencyCode.ILS]: 1149,
    [CurrencyCode.JPY]: 39900,
    [CurrencyCode.MYR]: 1499,
    [CurrencyCode.MXN]: 6999,
    [CurrencyCode.TWD]: 9900,
    [CurrencyCode.NZD]: 499,
    [CurrencyCode.NOK]: 2999,
    [CurrencyCode.PHP]: 17499,
    [CurrencyCode.PLN]: 1349,
    [CurrencyCode.GBP]: 249,
    [CurrencyCode.RUB]: 24999,
    [CurrencyCode.SGD]: 449,
    [CurrencyCode.SEK]: 2999,
    [CurrencyCode.CHF]: 299,
    [CurrencyCode.THB]: 11499,
    [CurrencyCode.USD]: 349,
  },
  699: {
    [CurrencyCode.AUD]: 999,
    [CurrencyCode.BRL]: 3749,
    [CurrencyCode.CAD]: 899,
    [CurrencyCode.CNY]: 4499,
    [CurrencyCode.CZK]: 14999,
    [CurrencyCode.DKK]: 4499,
    [CurrencyCode.EUR]: 599,
    [CurrencyCode.HKD]: 5499,
    [CurrencyCode.HUF]: 249900,
    [CurrencyCode.ILS]: 2299,
    [CurrencyCode.JPY]: 74900,
    [CurrencyCode.MYR]: 2949,
    [CurrencyCode.MXN]: 14049,
    [CurrencyCode.TWD]: 19900,
    [CurrencyCode.NZD]: 999,
    [CurrencyCode.NOK]: 6299,
    [CurrencyCode.PHP]: 34999,
    [CurrencyCode.PLN]: 2799,
    [CurrencyCode.GBP]: 499,
    [CurrencyCode.RUB]: 49999,
    [CurrencyCode.SGD]: 999,
    [CurrencyCode.SEK]: 6499,
    [CurrencyCode.CHF]: 649,
    [CurrencyCode.THB]: 23999,
    [CurrencyCode.USD]: 699,
  },
  1399: {
    [CurrencyCode.AUD]: 1899,
    [CurrencyCode.BRL]: 7499,
    [CurrencyCode.CAD]: 1749,
    [CurrencyCode.CNY]: 8999,
    [CurrencyCode.CZK]: 29999,
    [CurrencyCode.DKK]: 8999,
    [CurrencyCode.EUR]: 1199,
    [CurrencyCode.HKD]: 10999,
    [CurrencyCode.HUF]: 429900,
    [CurrencyCode.ILS]: 4499,
    [CurrencyCode.JPY]: 149900,
    [CurrencyCode.MYR]: 5999,
    [CurrencyCode.MXN]: 27999,
    [CurrencyCode.TWD]: 39900,
    [CurrencyCode.NZD]: 1999,
    [CurrencyCode.NOK]: 12499,
    [CurrencyCode.PHP]: 69999,
    [CurrencyCode.PLN]: 5499,
    [CurrencyCode.GBP]: 999,
    [CurrencyCode.RUB]: 99999,
    [CurrencyCode.SGD]: 1899,
    [CurrencyCode.SEK]: 12499,
    [CurrencyCode.CHF]: 1299,
    [CurrencyCode.THB]: 47999,
    [CurrencyCode.USD]: 1399,
  },
};

export const PromoCodeTypeFriendlyText = {
  [PromoCodeType.PERCENTAGE_ON_PURCHASE_PRICE]: 'Discount on purchase price',
};

export interface SrtConfigItem {
  displayName: string;
  languageCode: LanguageCode;
  formStateKey: string;
}

export const srtMapping: SrtConfigItem[] = [
  {
    displayName: 'English Subtitle (SRT) File',
    languageCode: LanguageCode.en_US,
    formStateKey: 'enUSSrtAssetID',
  },
  {
    displayName: 'Arabic Subtitle (SRT) File',
    languageCode: LanguageCode.ar,
    formStateKey: 'arSrtAssetID',
  },
  {
    displayName: 'German Subtitle (SRT) File',
    languageCode: LanguageCode.de_DE,
    formStateKey: 'deDESrtAssetID',
  },
  {
    displayName: 'French Subtitle (SRT) File',
    languageCode: LanguageCode.fr_FR,
    formStateKey: 'frFRSrtAssetID',
  },
  {
    displayName: 'Greek Subtitle (SRT) File',
    languageCode: LanguageCode.el_GR,
    formStateKey: 'elGRSrtAssetID',
  },
  {
    displayName: 'Hebrew Subtitle (SRT) File',
    languageCode: LanguageCode.he_IL,
    formStateKey: 'heILSrtAssetID',
  },
  {
    displayName: 'Hindi Subtitle (SRT) File',
    languageCode: LanguageCode.hi_IN,
    formStateKey: 'hiINSrtAssetID',
  },
  {
    displayName: 'Italian Subtitle (SRT) File',
    languageCode: LanguageCode.it_IT,
    formStateKey: 'itITSrtAssetID',
  },
  {
    displayName: 'Japanese Subtitle (SRT) File',
    languageCode: LanguageCode.ja_JP,
    formStateKey: 'jaJPSrtAssetID',
  },
  {
    displayName: 'Korean Subtitle (SRT) File',
    languageCode: LanguageCode.ko_KR,
    formStateKey: 'koKRSrtAssetID',
  },
  {
    displayName: 'Polish Subtitle (SRT) File',
    languageCode: LanguageCode.pl_PL,
    formStateKey: 'plPLSrtAssetID',
  },
  {
    displayName: 'Portuguese Subtitle (SRT) File',
    languageCode: LanguageCode.pt_PT,
    formStateKey: 'ptPTSrtAssetID',
  },
  {
    displayName: 'Russian Subtitle (SRT) File',
    languageCode: LanguageCode.ru_RU,
    formStateKey: 'ruRUSrtAssetID',
  },
  {
    displayName: 'Spanish Subtitle (SRT) File',
    languageCode: LanguageCode.es_ES,
    formStateKey: 'esESSrtAssetID',
  },
  {
    displayName: 'Tagalog Subtitle (SRT) File',
    languageCode: LanguageCode.tl_PH,
    formStateKey: 'tlPHSrtAssetID',
  },
  {
    displayName: 'Mandarin Subtitle (SRT) File',
    languageCode: LanguageCode.zh,
    formStateKey: 'zhSrtAssetID',
  },
];

export const LANGUAGE_TO_FLAG_MAPPING = {
  [LanguageCode.en_US]: 'gb.png',
  [LanguageCode.es_ES]: 'es.png',
  [LanguageCode.de_DE]: 'de.png',
  [LanguageCode.fr_FR]: 'fr.png',
  [LanguageCode.pt_PT]: 'pt.png',
  [LanguageCode.it_IT]: 'it.png',
  [LanguageCode.ru_RU]: 'ru.png',
  [LanguageCode.pl_PL]: 'pl.png',
  [LanguageCode.zh]: 'cn.png',
  [LanguageCode.ko_KR]: 'kr.png',
  [LanguageCode.tl_PH]: 'ph.png',
};

export const HUMAN_FRIENDLY_LANGUAGE_NAME_MAPPING = {
  [LanguageCode.en_US]: 'English',
  [LanguageCode.es_ES]: 'Spanish',
  [LanguageCode.de_DE]: 'German',
  [LanguageCode.fr_FR]: 'French',
  [LanguageCode.pt_PT]: 'Portuguese',
  [LanguageCode.it_IT]: 'Italian',
  [LanguageCode.ru_RU]: 'Russian',
  [LanguageCode.pl_PL]: 'Polish',
  [LanguageCode.zh]: 'Mandarin',
  [LanguageCode.ko_KR]: 'Korean',
  [LanguageCode.tl_PH]: 'Tagalog',
};
