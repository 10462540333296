import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { generateQRCode } from '../utils/generateQRCode';
import { getQRCodeLink } from '../utils/getQRCodeLink';
import { noop } from '../utils/noop';
import {
  HUMAN_FRIENDLY_LANGUAGE_NAME_MAPPING,
  LANGUAGE_TO_FLAG_MAPPING,
} from '../consts';
import { LoadingScreen } from './loading/LoadingScreen';

type Props = {
  document: any;
  documentProps: any;
  filename?: string;
  className?: string;
  style?: any;
  onDownloadSuccess?: () => void;
};

const DownloadActivationPDF = ({
  document: Doc,
  documentProps: { client, redeemCode, appURL, language },
  filename = 'Activation.pdf',
  className,
  style = {},
  onDownloadSuccess = noop,
}: Props) => {
  const humanFriendlyLanguage = HUMAN_FRIENDLY_LANGUAGE_NAME_MAPPING[language];
  const [processing, setProcessing] = useState(false);

  const generateActivationPDF = async () => {
    if (processing) {
      return;
    }

    setProcessing(true);

    const activationUrl = getQRCodeLink({
      redeemCode,
      appURL,
    });

    try {
      const qrCodeDataImgUrl = await generateQRCode(activationUrl);

      const logoURL = client?.logoImage?.uri;

      const processedProps = {
        activationUrl,
        qrCodeDataImgUrl,
        redeemCode,
        appURL,
        logoURL,
        language,
      };

      const blob = await pdf(<Doc {...processedProps} />).toBlob();

      saveAs(blob, filename);

      onDownloadSuccess();
    } catch (error) {
      console.error(error);

      toast.error('Failed to generate PDF. Please try again later.');
    }

    setProcessing(false);
  };

  const imageUrl = `/flags/${LANGUAGE_TO_FLAG_MAPPING[language]}`;

  return (
    <Container>
      <img
        width="100%"
        height="100%"
        src={imageUrl}
        alt="Download Activation PDF"
        onClick={generateActivationPDF}
        className={className}
        style={{
          ...style,
          position: 'relative',
          cursor: 'pointer',
          border: '1px solid black',
          opacity: processing ? 0.5 : 1,
        }}
      />
      <span>{humanFriendlyLanguage}</span>
      {processing && (
        <LoadingAnimationContainer>
          <LoadingScreen />
        </LoadingAnimationContainer>
      )}
    </Container>
  );
};

export default DownloadActivationPDF;

const LoadingAnimationContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
