import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signOut } from '../../store/auth/actions';
import { isAuthenticated, selectPrevJWT } from '../../store/auth/selectors';
import { usePrevious } from '../../hooks/usePrevious';
import { ROUTES } from '../../routes/internalRoutes/internalRouteDefinitions';

interface Props {
  close: () => void;
}

export const LogoutDialog = ({ close }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const prevJWT = useSelector(selectPrevJWT);
  const prePrevJWT = usePrevious(prevJWT);
  const isAuted = useSelector(isAuthenticated);

  useEffect(() => {
    if (prePrevJWT && !prevJWT) {
      history.push(ROUTES.CLIENTS);
      close();
    } else if (!isAuted) {
      close();
    }
  }, [history, prevJWT, prePrevJWT, isAuted, close]);

  return (
    <Dialog
      open
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Log Out</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to log out from this session?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(signOut());
          }}
          color="primary"
          variant="outlined"
          autoFocus
        >
          Yes
        </Button>
        <Button onClick={close} color="secondary" variant="outlined">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
