import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Link,
  Image,
  Font,
} from '@react-pdf/renderer';
import { LanguageCode } from '../graphql/globalTypes';

Font.register({
  family: 'RobotoCondensed',
  src: 'https://gaia.lithodomos.com/f1123f4b3d926ac4f72cc8091a4b5d19.ttf',
});

// NotoSansCJKsc-Regular.ttf
Font.register({
  family: 'NotoSansCJKsc-Regular',
  src: '/fonts/NotoSansCJKsc-Regular.ttf',
});

const REDEEM_CODE_ICON_URL =
  'https://gaia.lithodomos.com/d1998b53d5cea9814dd2d79b64a58da5.png';

function getStyles(language: LanguageCode) {
  let fontFamily = 'RobotoCondensed';

  if (language === LanguageCode.zh || language === LanguageCode.ko_KR) {
    fontFamily = 'NotoSansCJKsc-Regular';
  }

  return StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      padding: 20,
      fontFamily,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      marginBottom: 8,
      textDecoration: 'underline',
    },
    sectionTitle: {
      fontSize: 18,
      marginBottom: 8,
    },
    instruction: {
      fontSize: 12,
      marginBottom: 8,
    },
    centeredInstruction: {
      fontSize: 12,
      marginBottom: 8,
      textAlign: 'center',
    },
    section: {
      margin: 8,
      padding: 8,
    },
    hr: {
      height: 1,
      backgroundColor: 'black',
      width: '100%',
    },
    qrCodeImage: {
      margin: 'auto',
      width: 150,
      height: 150,
    },
    icon: {
      width: 16,
      height: 16,
    },
    centeredText: {
      fontSize: 12,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    logo: {
      position: 'absolute',
      top: 10,
      left: 10,
      width: 120,
      height: 120,
    },
    logoImg: {
      width: 120,
      height: 120,
    },
    linkText: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 10,
    },
  });
}

function getTranslations(language: LanguageCode, styles: any) {
  const TRANSLATIONS = {
    [LanguageCode.en_US]: {
      title: 'How to activate a tour',
      instructions:
        'Please follow the instructions below to activate your tour.',
      scanTapClick: 'SCAN / TAP / CLICK',
      method1: 'Method 1: If you have the digital copy of this document:',
      method1Instruction:
        'Tap/click the QR code above. This will launch the tour in your default browser.',
      method2: 'Method 2: If you have a printed version of this document:',
      method2Step1: '1. Open the camera.',
      method2Step2: '2. Aim at the QR code above.',
      method2Step3: '3. Tap the link that pops up on your phone.',
      method2Step4: 'This will launch the tour in your default browser.',
      manualActivation: 'Manual activation:',
      manualActivationInstructions:
        'If the automatic activation does not work, you can manually activate the tour by following these steps:',
      step1: (appURL) => (
        <>
          Visit <Link src={appURL}>{appURL}</Link> in your browser.
        </>
      ),
      step2: () => (
        <>
          <Image style={styles.icon} src={REDEEM_CODE_ICON_URL} /> at the top
          right corner.
        </>
      ),
      step3: () => <>Log In / Sign Up</>,
      step4: (redeemCode) => <>Enter code {redeemCode} and press REDEEM.</>,
    },
    [LanguageCode.es_ES]: {
      title: 'Cómo activar un tour',
      instructions:
        'Por favor, siga las instrucciones a continuación para activar su tour.',
      scanTapClick: 'ESCANEA / TOCA / HAZ CLIC',
      method1: 'Método 1: Si tiene la copia digital de este documento:',
      method1Instruction:
        'Toca/haz clic en el código QR de arriba. Esto iniciará la visita en tu navegador predeterminado.',
      method2: 'Método 2: Si tiene una versión impresa de este documento:',
      method2Step1: '1. Abre la cámara.',
      method2Step2: '2. Apunta al código QR de arriba.',
      method2Step3: '3. Toca el enlace que aparece en el teléfono.',
      method2Step4: 'Esto iniciará la visita en tu navegador predeterminado.',
      manualActivation: 'Activación manual:',
      manualActivationInstructions:
        'Si la activación automática no funciona, puede activar manualmente el tour siguiendo estos pasos:',
      step1: (appURL) => (
        <>
          Visita <Link src={appURL}>{appURL}</Link> en tu navegador.
        </>
      ),
      step2: () => (
        <>
          <Image style={styles.icon} src={REDEEM_CODE_ICON_URL} /> en la esquina
          superior derecha.
        </>
      ),
      step3: () => <>INICIAR SESIÓN / REGISTRARSE</>,
      step4: (redeemCode) => (
        <>Introduce el código {redeemCode} y haz clic en CANJEAR.</>
      ),
    },
    [LanguageCode.fr_FR]: {
      title: 'Comment activer une visite',
      instructions:
        'Veuillez suivre les instructions ci-dessous pour activer votre visite.',
      scanTapClick: 'SCANNER / TAPOTER / CLIQUER',
      method1: 'Méthode 1: Si vous avez la copie numérique de ce document:',
      method1Instruction:
        'Tapez/cliquez sur le code QR ci-dessus. Cela lancera la visite dans votre navigateur par défaut.',
      method2: 'Méthode 2: Si vous avez une version imprimée de ce document:',
      method2Step1: '1. Ouvrez l’appareil photo.',
      method2Step2: '2. Visez le code QR ci-dessus.',
      method2Step3: '3. Appuyez sur le lien qui apparaît sur le téléphone.',
      method2Step4: 'Cela lancera la visite dans votre navigateur par défaut.',
      manualActivation: 'Activation manuelle:',
      manualActivationInstructions:
        'Si l’activation automatique ne fonctionne pas, vous pouvez activer manuellement la visite en suivant ces étapes:',
      step1: (appURL) => (
        <>
          Visitez <Link src={appURL}>{appURL}</Link> dans votre navigateur.
        </>
      ),
      step2: () => (
        <>
          <Image style={styles.icon} src={REDEEM_CODE_ICON_URL} /> en haut à
          droite.
        </>
      ),
      step3: () => <>SE CONNECTER / S'INSCRIRE</>,
      step4: (redeemCode) => (
        <>Entrez le code {redeemCode} et cliquez sur ECHANGER.</>
      ),
    },
    [LanguageCode.de_DE]: {
      title: 'So aktivieren Sie eine Tour',
      instructions:
        'Bitte befolgen Sie die folgenden Anweisungen, um Ihre Tour zu aktivieren.',
      scanTapClick: 'SCANNEN / TIPPEN / KLICKEN',
      method1: 'Methode 1: Wenn Sie die digitale Kopie dieses Dokuments haben:',
      method1Instruction:
        'Tippen/klicken Sie auf den QR-Code oben. Dadurch wird die Tour in Ihrem Standardbrowser gestartet.',
      method2:
        'Methode 2: Wenn Sie eine gedruckte Version dieses Dokuments haben:',
      method2Step1: '1. Öffnen Sie die Kamera.',
      method2Step2: '2. Richten Sie die Kamera auf den QR-Code oben.',
      method2Step3:
        '3. Tippen Sie auf den Link, der auf Ihrem Telefon erscheint.',
      method2Step4: 'Dadurch wird die Tour in Ihrem Standardbrowser gestartet.',
      manualActivation: 'Manuelle Aktivierung:',
      manualActivationInstructions:
        'Wenn die automatische Aktivierung nicht funktioniert, können Sie die Tour manuell aktivieren, indem Sie die folgenden Schritte ausführen:',
      step1: (appURL) => (
        <>
          Besuchen Sie <Link src={appURL}>{appURL}</Link> in Ihrem Browser.
        </>
      ),
      step2: () => (
        <>
          <Image style={styles.icon} src={REDEEM_CODE_ICON_URL} /> in der oberen
          rechten Ecke.
        </>
      ),
      step3: () => <>EINLOGGEN ANMELDEN</>,
      step4: (redeemCode) => (
        <>Geben Sie den Code {redeemCode} ein und tippen Sie auf TILGEN.</>
      ),
    },
    [LanguageCode.pt_PT]: {
      title: 'Como ativar um tour',
      instructions:
        'Por favor, siga as instruções abaixo para ativar o seu tour.',
      scanTapClick: 'ESCANEIE / TOQUE / CLIQUE',
      method1: 'Método 1: Se você tem a cópia digital deste documento:',
      method1Instruction:
        'Toque/clique no código QR acima. Isso iniciará o tour no seu navegador padrão.',
      method2: 'Método 2: Se você tem uma versão impressa deste documento:',
      method2Step1: '1. Abra a câmera.',
      method2Step2: '2. Aponte para o código QR acima.',
      method2Step3: '3. Toque no link que aparece no seu telefone.',
      method2Step4: 'Isso iniciará o tour no seu navegador padrão.',
      manualActivation: 'Ativação manual:',
      manualActivationInstructions:
        'Se a ativação automática não funcionar, você pode ativar o tour manualmente seguindo estes passos:',
      step1: (appURL) => (
        <>
          Visite <Link src={appURL}>{appURL}</Link> no seu navegador.
        </>
      ),
      step2: () => (
        <>
          <Image style={styles.icon} src={REDEEM_CODE_ICON_URL} /> no canto
          superior direito.
        </>
      ),
      step3: () => <>INICIAR SESSÃO / INSCRIVER-SE</>,
      step4: (redeemCode) => (
        <>Digite o código {redeemCode} e pressione RESGATAR.</>
      ),
    },
    [LanguageCode.it_IT]: {
      title: 'Come attivare un tour',
      instructions: 'Segui le istruzioni di seguito per attivare il tuo tour.',
      scanTapClick: 'SCANSIONA / TOCCA / CLICCA',
      method1: 'Metodo 1: Se hai la copia digitale di questo documento:',
      method1Instruction:
        'Tocca/clicca il codice QR sopra. Questo avvierà il tour nel tuo browser predefinito.',
      method2: 'Metodo 2: Se hai una versione stampata di questo documento:',
      method2Step1: '1. Apri la fotocamera.',
      method2Step2: '2. Inquadra il codice QR sopra.',
      method2Step3: '3. Tocca il link che appare sul tuo telefono.',
      method2Step4: 'Questo avvierà il tour nel tuo browser predefinito.',
      manualActivation: 'Attivazione manuale:',
      manualActivationInstructions:
        'Se l’attivazione automatica non funziona, puoi attivare manualmente il tour seguendo questi passaggi:',
      step1: (appURL) => (
        <>
          Visita <Link src={appURL}>{appURL}</Link> nel tuo browser.
        </>
      ),
      step2: () => (
        <>
          <Image style={styles.icon} src={REDEEM_CODE_ICON_URL} /> in alto a
          destra.
        </>
      ),
      step3: () => <>ACCEDI/REGISTRATI</>,
      step4: (redeemCode) => (
        <>Inserisci il codice {redeemCode} e premi RISCATTA.</>
      ),
    },
    [LanguageCode.ru_RU]: {
      title: 'Как активировать тур',
      instructions:
        'Пожалуйста, следуйте инструкциям ниже, чтобы активировать тур.',
      scanTapClick: 'СКАНИРОВАТЬ / НАЖАТЬ / КЛИК',
      method1: 'Способ 1: Если у вас есть цифровая копия этого документа:',
      method1Instruction:
        'Нажмите/кликните по QR-коду выше. Это запустит тур в вашем браузере по умолчанию.',
      method2: 'Способ 2: Если у вас есть печатная версия этого документа:',
      method2Step1: '1. Откройте камеру.',
      method2Step2: '2. Наведите на QR-код выше.',
      method2Step3: '3. Нажмите на ссылку, которая появится на вашем телефоне.',
      method2Step4: 'Это запустит тур в вашем браузере по умолчанию.',
      manualActivation: 'Ручная активация:',
      manualActivationInstructions:
        'Если автоматическая активация не работает, вы можете активировать тур вручную, выполнив следующие шаги:',
      step1: (appURL) => (
        <>
          Посетите <Link src={appURL}>{appURL}</Link> в вашем браузере.
        </>
      ),
      step2: () => (
        <>
          <Image style={styles.icon} src={REDEEM_CODE_ICON_URL} /> в правом
          верхнем углу.
        </>
      ),
      step3: () => <>ВОЙТИ / ЗАРЕГИСТРИРОВАТЬСЯ</>,
      step4: (redeemCode) => <>Введите код {redeemCode} и нажмите ВЫКУПИТЬ.</>,
    },
    [LanguageCode.pl_PL]: {
      title: 'Jak aktywować wycieczkę',
      instructions:
        'Proszę postępować zgodnie z poniższymi instrukcjami, aby aktywować wycieczkę.',
      scanTapClick: 'SKANUJ / DOTYK / KLIK',
      method1: 'Metoda 1: Jeśli posiadasz cyfrową kopię tego dokumentu:',
      method1Instruction:
        'Dotknij/kliknij powyższy kod QR. Spowoduje to uruchomienie wycieczki w domyślnej przeglądarce.',
      method2: 'Metoda 2: Jeśli posiadasz wydrukowaną wersję tego dokumentu:',
      method2Step1: '1. Otwórz aparat.',
      method2Step2: '2. Skieruj aparat na powyższy kod QR.',
      method2Step3: '3. Kliknij link, który pojawi się na twoim telefonie.',
      method2Step4:
        'Spowoduje to uruchomienie wycieczki w domyślnej przeglądarce.',
      manualActivation: 'Ręczna aktywacja:',
      manualActivationInstructions:
        'Jeśli automatyczna aktywacja nie działa, możesz aktywować wycieczkę ręcznie, wykonując następujące kroki:',
      step1: (appURL) => (
        <>
          Odwiedź <Link src={appURL}>{appURL}</Link> w swojej przeglądarce.
        </>
      ),
      step2: () => (
        <>
          <Image style={styles.icon} src={REDEEM_CODE_ICON_URL} /> w prawym
          górnym rogu.
        </>
      ),
      step3: () => <>ZALOGUJ SIĘ / ZAREJESTRUJ SIĘ</>,
      step4: (redeemCode) => <>Wpisz kod {redeemCode} i naciśnij ZREALIZUJ.</>,
    },
    [LanguageCode.zh]: {
      title: '如何激活游览',
      instructions: '请按照以下说明激活您的游览。',
      scanTapClick: '扫描 / 轻触 / 点击',
      method1: '方法1：如果您拥有该文件的数字副本：',
      method1Instruction: '点击上方的二维码。这将在您的默认浏览器中启动游览。',
      method2: '方法2：如果您拥有该文件的打印版本：',
      method2Step1: '1. 打开相机。',
      method2Step2: '2. 对准上方的二维码。',
      method2Step3: '3. 点击手机上弹出的链接。',
      method2Step4: '这将在您的默认浏览器中启动游览。',
      manualActivation: '手动激活：',
      manualActivationInstructions:
        '如果自动激活无效，您可以按照以下步骤手动激活游览：',
      step1: (appURL) => (
        <>
          在浏览器中访问 <Link src={appURL}>{appURL}</Link>.
        </>
      ),
      step2: () => (
        <>
          <Image style={styles.icon} src={REDEEM_CODE_ICON_URL} /> 位于右上角.
        </>
      ),
      step3: () => <>登录／注册</>,
      step4: (redeemCode) => <>输入代码 {redeemCode} 并点击 购物车.</>,
    },
    [LanguageCode.ko_KR]: {
      title: '투어 활성화 방법',
      instructions: '투어를 활성화하려면 아래의 지침을 따르세요.',
      scanTapClick: '스캔 / 탭 / 클릭',
      method1: '방법 1: 이 문서의 디지털 사본이 있는 경우:',
      method1Instruction:
        '위의 QR 코드를 탭/클릭하세요. 기본 브라우저에서 투어가 시작됩니다.',
      method2: '방법 2: 이 문서의 인쇄본이 있는 경우:',
      method2Step1: '1. 카메라를 엽니다.',
      method2Step2: '2. 위의 QR 코드를 향해 카메라를 조준합니다.',
      method2Step3: '3. 전화에 표시되는 링크를 탭하세요.',
      method2Step4: '기본 브라우저에서 투어가 시작됩니다.',
      manualActivation: '수동 활성화:',
      manualActivationInstructions:
        '자동 활성화가 작동하지 않으면 다음 단계를 따라 투어를 수동으로 활성화할 수 있습니다:',
      step1: (appURL) => (
        <>
          브라우저에서 <Link src={appURL}>{appURL}</Link>를 방문하세요.
        </>
      ),
      step2: () => (
        <>
          <Image style={styles.icon} src={REDEEM_CODE_ICON_URL} /> 오른쪽 상단에
          있습니다.
        </>
      ),
      step3: () => <>로그인 / 가입</>,
      step4: (redeemCode) => (
        <>코드 {redeemCode}를 입력하고 상환하다를 누르세요.</>
      ),
    },
    [LanguageCode.tl_PH]: {
      title: 'Paano i-activate ang tour',
      instructions:
        'Sundin ang mga tagubiling nasa ibaba para i-activate ang iyong tour.',
      scanTapClick: 'I-SCAN / I-TAP / I-CLICK',
      method1:
        'Paraan 1: Kung mayroon kang digital na kopya ng dokumentong ito:',
      method1Instruction:
        'I-tap/i-click ang QR code sa itaas. Bubuksan nito ang tour sa iyong default browser.',
      method2:
        'Paraan 2: Kung mayroon kang naka-print na bersyon ng dokumentong ito:',
      method2Step1: '1. Buksan ang camera.',
      method2Step2: '2. Itutok ang camera sa QR code sa itaas.',
      method2Step3: '3. I-tap ang link na lalabas sa iyong telepono.',
      method2Step4: 'Bubuksan nito ang tour sa iyong default browser.',
      manualActivation: 'Manwal na pag-activate:',
      manualActivationInstructions:
        'Kung hindi gumana ang awtomatikong pag-activate, maaari mong manu-manong i-activate ang tour sa pamamagitan ng pagsunod sa mga sumusunod na hakbang:',
      step1: (appURL) => (
        <>
          Bisitahin ang <Link src={appURL}>{appURL}</Link> sa iyong browser.
        </>
      ),
      step2: () => (
        <>
          <Image style={styles.icon} src={REDEEM_CODE_ICON_URL} /> sa kanang
          itaas na sulok.
        </>
      ),
      step3: () => <>MAG -LOG IN / MAG -SIGN UP</>,
      step4: (redeemCode) => (
        <>Ilagay ang code {redeemCode} at pindutin ang TUBOSIN.</>
      ),
    },
  };

  return TRANSLATIONS[language] || TRANSLATIONS[LanguageCode.en_US];
}

type Props = {
  activationUrl: string;
  redeemCode: string;
  qrCodeDataImgUrl: string;
  appURL: string;
  logoURL?: string;
  language?: LanguageCode;
};

const PDFActivation = ({
  activationUrl,
  qrCodeDataImgUrl,
  redeemCode,
  appURL = 'ancient-world.co',
  logoURL,
  language = LanguageCode.en_US,
}: Props) => {
  // Select translations based on language, falling back to en_US if not found.
  const styles = getStyles(language);

  const t = getTranslations(language, styles);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {logoURL && (
          <View style={styles.logo}>
            <Image src={logoURL} style={styles.logoImg} />
          </View>
        )}
        <View style={styles.section}>
          <Text style={styles.title}>{t.title}</Text>
          <Text style={styles.centeredInstruction}>{t.instructions}</Text>
        </View>
        <View style={styles.section}>
          <Link src={activationUrl}>
            <Image style={styles.qrCodeImage} src={qrCodeDataImgUrl} />
          </Link>
          <Link src={activationUrl}>
            <Text style={styles.linkText}>{activationUrl}</Text>
          </Link>
          <View>
            <Text style={styles.centeredText}>{t.scanTapClick}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>{t.method1}</Text>
          <Text style={styles.instruction}>{t.method1Instruction}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>{t.method2}</Text>
          <Text style={styles.instruction}>{t.method2Step1}</Text>
          <Text style={styles.instruction}>{t.method2Step2}</Text>
          <Text style={styles.instruction}>{t.method2Step3}</Text>
          <Text style={styles.instruction}>{t.method2Step4}</Text>
        </View>
        <View style={styles.hr} />
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>{t.manualActivation}</Text>
          <Text style={styles.instruction}>
            {t.manualActivationInstructions}
          </Text>
          <Text style={styles.instruction}>{t.step1(appURL)}</Text>
          <Text style={styles.instruction}>{t.step2()}</Text>
          <Text style={styles.instruction}>{t.step3()}</Text>
          <Text style={styles.instruction}>{t.step4(redeemCode)}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PDFActivation;
