import QRCode from 'qrcode.react';
import styled from 'styled-components';
import { Redeem as RedeemIcon } from '@material-ui/icons';
import { getQRCodeLink } from '../utils/getQRCodeLink';

type Props = {
  redeemCode: string;
  appURL: string | null;
};

export const PrintableTicketIntructions = (props: Props) => {
  const link = getQRCodeLink(props);

  return (
    <>
      <section>
        <p style={{ fontSize: '5mm' }}>
          <strong>Instructions:</strong>
        </p>

        <p>Method 1:</p>

        <table>
          <tbody>
            <tr>
              <td>1. Open the camera.</td>
            </tr>
            <tr>
              <td>2. Aim at QR code.</td>
            </tr>
            <tr>
              <td>3. Tap the link.</td>
            </tr>
          </tbody>
        </table>

        <a href={link}>
          <QRCodeContainer>
            <QRCode level="M" size={150} renderAs="svg" value={link} />
          </QRCodeContainer>
        </a>
      </section>
      <section>
        <p>Method 2:</p>

        <table>
          <tbody>
            <tr>
              <td>1. Visit:</td>
            </tr>

            <tr>
              <td
                style={{
                  overflowWrap: 'break-word',
                  wordBreak: 'break-all',
                  maxWidth: '100%',
                }}
              >
                &nbsp;&nbsp;&nbsp;
                <strong>{props.appURL || 'ancient-world.co'}</strong>
              </td>
            </tr>

            <tr>
              <td>
                2. Click the <RedeemIcon fontSize="small" /> icon.
              </td>
            </tr>

            <tr>
              <td>3. Sign up.</td>
            </tr>

            <tr>
              <td>4. Enter code:</td>
            </tr>

            <tr>
              <td>
                &nbsp;&nbsp;&nbsp;<strong>{props.redeemCode}</strong>
              </td>
            </tr>

            <tr>
              <td>5. Click REDEEM.</td>
            </tr>
          </tbody>
        </table>
      </section>
    </>
  );
};

const QRCodeContainer = styled.div`
  margin: 6mm auto 0;
  display: flex;
  justify-content: center;
`;
