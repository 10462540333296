import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Button } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { toast } from 'react-toastify';
import { captureEvent, DataKeys, EventNames } from '../../features/analytics';
import {
  CONSOLE_DistributeRedeemCode as Data,
  CONSOLE_DistributeRedeemCodeVariables as Variables,
} from '../../graphql/mutations/__generated__/CONSOLE_DistributeRedeemCode';
import { DISTRIBUTE_REDEEM_CODE } from '../../graphql/mutations/DistributeRedeemCode';
import DownloadActivationPDF from '../../components/DownloadActivationPDF';
import PDFActivation from '../../components/PDFActivation';
import useRouteChange from '../../hooks/useRouteChange';
import { LanguageCode } from '../../graphql/globalTypes';
import { PrintableTicketIntructions } from '../../components/PrintableTicketIntructions';

interface Props {
  close: () => void;
  modalData: {
    redeemCode: string;
    distributedAt: string | null;
    appURL: string | null;
    client: {
      logoImage?: {
        uri: string;
      } | null;
    };
  };
}

const MULTI_LANGUAGE_ACTIVATIONS = [
  {
    languageCode: LanguageCode.en_US,
    filename: (redeemCode) =>
      `Tour-Activation-Instructions-${redeemCode}-English.pdf`,
  },
  {
    languageCode: LanguageCode.es_ES,
    filename: (redeemCode) =>
      `Tour-Activation-Instructions-${redeemCode}-Spanish.pdf`,
  },
  {
    languageCode: LanguageCode.de_DE,
    filename: (redeemCode) =>
      `Tour-Activation-Instructions-${redeemCode}-German.pdf`,
  },
  {
    languageCode: LanguageCode.fr_FR,
    filename: (redeemCode) =>
      `Tour-Activation-Instructions-${redeemCode}-French.pdf`,
  },
  {
    languageCode: LanguageCode.pt_PT,
    filename: (redeemCode) =>
      `Tour-Activation-Instructions-${redeemCode}-Portuguese.pdf`,
  },
  {
    languageCode: LanguageCode.it_IT,
    filename: (redeemCode) =>
      `Tour-Activation-Instructions-${redeemCode}-Italian.pdf`,
  },
  {
    languageCode: LanguageCode.ru_RU,
    filename: (redeemCode) =>
      `Tour-Activation-Instructions-${redeemCode}-Russian.pdf`,
  },
  {
    languageCode: LanguageCode.pl_PL,
    filename: (redeemCode) =>
      `Tour-Activation-Instructions-${redeemCode}-Polish.pdf`,
  },
  {
    languageCode: LanguageCode.zh,
    filename: (redeemCode) =>
      `Tour-Activation-Instructions-${redeemCode}-Chinese.pdf`,
  },
  {
    languageCode: LanguageCode.ko_KR,
    filename: (redeemCode) =>
      `Tour-Activation-Instructions-${redeemCode}-Korean.pdf`,
  },
  {
    languageCode: LanguageCode.tl_PH,
    filename: (redeemCode) =>
      `Tour-Activation-Instructions-${redeemCode}-Tagalog.pdf`,
  },
];

// this is a trickery.
// basically, we make use of the modals feature, but instead of showing a modal,
// we mount a component which does not render unless in screen mode. only shows in printing mode.
// the component has a z-index of 2 as well.
// everything else is hidden using not-printable css class in printing mode. (including the toast)
export const PrintRedeemCode = ({ close, modalData }: Props) => {
  const { redeemCode, appURL, client } = modalData;

  let variables: Variables | null = null;

  const getVariables = () => variables;

  const [setDistributed, { loading }] = useMutation<Data, Variables>(
    DISTRIBUTE_REDEEM_CODE,
    {
      onError: (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', getVariables());
          Sentry.captureException(error);
        });
        toast.error(error.message);
      },
      onCompleted: ({ result }) => {
        const { distributed, error } = result;

        if (distributed) {
          toast.info('Code marked as distributed', {
            toastId: 'distributed',
          });
        } else {
          const errorMessage = error?.message || 'Unknown error';

          toast.error(errorMessage, {
            toastId: errorMessage,
          });
        }
      },
    }
  );

  // let distributedButtonCaption = 'Distribute code';

  // if (distributed) {
  //   distributedButtonCaption = 'Code has been distributed';
  // } else if (loading) {
  //   distributedButtonCaption = 'Distributing...';
  // }

  useEffect(
    () => {
      window.addEventListener('afterprint', (event) => {
        setDistributed({
          variables: {
            input: {
              redeemCode,
              distributed: true,
            },
          },
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useRouteChange(close);

  const print = () => {
    captureEvent({
      name: EventNames.PRINT_TICKET_ATTEMPTED,
      data: [
        {
          key: DataKeys.REDEEM_CODE,
          value: redeemCode,
        },
      ],
    });

    // open print dialog
    window.print();
  };

  return (
    <PrintContainer>
      <PrintStyle />

      <PrintableActivationCodeContainer className="printable" id="print-ticket">
        <PrintableTicketIntructions redeemCode={redeemCode} appURL={appURL} />
      </PrintableActivationCodeContainer>

      <CloseIcon
        className="not-printable"
        style={{
          position: 'fixed',
          top: '10px',
          right: '10px',
        }}
        onClick={close}
      />

      <ButtonsContainer className="not-printable">
        <FlagsTitle>Download a PDF ticket</FlagsTitle>
        <p>Select a flag to download the ticket in specific language</p>
        <FlagsGrid>
          {MULTI_LANGUAGE_ACTIVATIONS.map(({ languageCode, filename }) => (
            <DownloadActivationPDF
              key={languageCode}
              document={PDFActivation}
              documentProps={{
                redeemCode,
                appURL,
                client,
                language: languageCode,
              }}
              onDownloadSuccess={() => {
                // Mark the code as distributed after the PDF is downloaded
                setDistributed({
                  variables: {
                    input: {
                      redeemCode,
                      distributed: true,
                    },
                  },
                });
              }}
              filename={filename(redeemCode)}
              className="not-printable"
              style={{ marginTop: '4mm' }}
            />
          ))}
        </FlagsGrid>

        <Button
          onClick={print}
          color="primary"
          autoFocus
          variant="outlined"
          size="large"
          className="not-printable"
          style={{ marginTop: '4mm' }}
          disabled={loading}
        >
          {loading ? 'Please wait...' : 'Print with a Ticket Printer'}
        </Button>

        <Button
          onClick={close}
          color="secondary"
          variant="contained"
          size="large"
          className="not-printable"
          style={{ marginTop: '4mm' }}
          disabled={loading}
        >
          Close
        </Button>
      </ButtonsContainer>
    </PrintContainer>
  );
};

const PrintableActivationCodeContainer = styled.div`
  padding: 0 2mm;
`;

const PrintContainer = styled.div`
  z-index: 9999999999999999999999;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  right: 0;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media print {
    padding: 0;
    height: auto;
    align-items: flex-start;
  }

  @page {
    padding: 0;
    margin: 5mm 0 0 5mm;
    size: 55mm 140mm;
  }
`;

const PrintStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'courier', sans-serif;
  }

  p {
    padding: 0;
    margin-bottom: 2mm;
    font-size: 5mm;
  }

  td {
    padding: 0;
    margin-bottom: 2mm;
    font-size: 5mm;
  }

  section {
    page-break-inside: avoid;
    /* page-break-before: avoid;
    page-break-after: avoid; */
    margin-bottom: 6mm;
  }

  section:last-child {
    margin-bottom: 0;
  }
`;

const ButtonsContainer = styled.div`
  width: 300px;
  display: grid;
  grid-template-columns: 1fr;
  margin: 10mm auto;
`;

const FlagsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  margin-bottom: 10px;
`;

const FlagsTitle = styled.p`
  padding: 6px;
  color: #fff;
  background: #000;
  border-radius: 4px;
  text-align: center;
`;
