import { useDispatch, useSelector } from 'react-redux';
import { selectModalData, selectModalType } from '../../store/modal/selectors';
import { ModalType } from '../../store/modal/types';
import { setModalType } from '../../store/modal/actions';
import { PrintRedeemCode } from './PrintRedeemCode';
import { LogoutDialog } from './LogoutDialog';
import { ViewpointPublishingErrorsDialog } from './ViewpointPublishingErrorsDialog';
import { DownloadClientRedemptionCodesCSV } from './DownloadClientRedemptionCodesCSV';
import { StopPublishingErrorsDialog } from './StopPublishingErrorsDialog';
import { PrintBundleRedeemCode } from './PrintBundleRedeemCode';
import { DownloadClientBundleRedemptionCodesPDF } from './DownloadClientBundleRedemptionCodesPDF';
import { DownloadClientBundleRedemptionCodesCSV } from './DownloadClientBundleRedemptionCodesCSV';
import { DownloadClientRedemptionCodesPDF } from './DownloadClientRedemptionCodesPDF';

export const Modals = () => {
  const dispatch = useDispatch();
  const modalType = useSelector(selectModalType);
  const modalData = useSelector(selectModalData);

  const close = () => {
    dispatch(setModalType({ modalType: ModalType.NONE, modalData: null }));
  };

  if (modalType === ModalType.PRINT_BUNDLE_REDEEM_CODE) {
    return <PrintBundleRedeemCode close={close} show modalData={modalData} />;
  }

  if (modalType === ModalType.PRINT_REDEEM_CODE) {
    return <PrintRedeemCode close={close} modalData={modalData} />;
  }

  if (modalType === ModalType.LOGOUT) {
    return <LogoutDialog close={close} />;
  }

  if (modalType === ModalType.VIEPWOINT_PUBLISH_ERRORS) {
    return (
      <ViewpointPublishingErrorsDialog isComplete={modalData} close={close} />
    );
  }

  if (modalType === ModalType.DOWNLOAD_CLIENT_BUNDLE_REDEMPTION_CODES_PDF) {
    return (
      <DownloadClientBundleRedemptionCodesPDF data={modalData} close={close} />
    );
  }

  if (modalType === ModalType.DOWNLOAD_CLIENT_BUNDLE_REDEMPTION_CODES_CSV) {
    return (
      <DownloadClientBundleRedemptionCodesCSV data={modalData} close={close} />
    );
  }

  if (modalType === ModalType.STOP_PUBLISH_ERRORS) {
    return <StopPublishingErrorsDialog isComplete={modalData} close={close} />;
  }

  if (modalType === ModalType.DOWNLOAD_CLIENT_REDEMPTION_CODES_CSV) {
    return <DownloadClientRedemptionCodesCSV data={modalData} close={close} />;
  }

  if (modalType === ModalType.DOWNLOAD_CLIENT_REDEMPTION_CODES_PDF) {
    return <DownloadClientRedemptionCodesPDF data={modalData} close={close} />;
  }

  return null;
};
