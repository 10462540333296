/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccessDateField {
  consumedAt = "consumedAt",
  createdAt = "createdAt",
  distributedAt = "distributedAt",
  expiresAt = "expiresAt",
}

export enum AccessRestriction {
  MULTIPLE_DEVICES = "MULTIPLE_DEVICES",
  SINGLE_DEVICE_MULTIPLE_USE = "SINGLE_DEVICE_MULTIPLE_USE",
  SINGLE_USE = "SINGLE_USE",
}

export enum AccessSortField {
  consumedAt = "consumedAt",
  createdAt = "createdAt",
  expiresAt = "expiresAt",
}

export enum AccessSubType {
  GUIDE = "GUIDE",
  TRAVELLER = "TRAVELLER",
}

export enum AccessType {
  ANCIENT_WORLD_ACTIVATE_ANY_SET_NUMBER_OF_TOURS = "ANCIENT_WORLD_ACTIVATE_ANY_SET_NUMBER_OF_TOURS",
  ANCIENT_WORLD_ACTIVATE_ANY_TOUR = "ANCIENT_WORLD_ACTIVATE_ANY_TOUR",
  ANCIENT_WORLD_ACTIVATE_SELECTED_TOURS = "ANCIENT_WORLD_ACTIVATE_SELECTED_TOURS",
  ANCIENT_WORLD_ACTIVATE_SELECTED_TOURS_LIMITED = "ANCIENT_WORLD_ACTIVATE_SELECTED_TOURS_LIMITED",
  EMAIL_AND_PASSWORD = "EMAIL_AND_PASSWORD",
  REDEEM_CODE = "REDEEM_CODE",
}

export enum ActivationCountUnit {
  DAY = "DAY",
  MONTH = "MONTH",
  WEEK = "WEEK",
  YEAR = "YEAR",
}

export enum AffiliateCodetype {
  ARTICLE = "ARTICLE",
  EMAIL = "EMAIL",
  GENERAL = "GENERAL",
  NEWS = "NEWS",
  WEBSITE = "WEBSITE",
}

export enum AppType {
  ANCIENT_WORLD = "ANCIENT_WORLD",
  ANCIENT_WORLD_STAGING = "ANCIENT_WORLD_STAGING",
  ANCIENT_WORLD_WHITE_LABEL = "ANCIENT_WORLD_WHITE_LABEL",
  ANCIENT_WORLD_WHITE_LABEL_STAGING = "ANCIENT_WORLD_WHITE_LABEL_STAGING",
  EXPEDITION = "EXPEDITION",
  UNITY_BESPOKE = "UNITY_BESPOKE",
  UNITY_INTERNAL = "UNITY_INTERNAL",
}

export enum ArtefactI18nInfoField {
  subtitle = "subtitle",
  title = "title",
}

export enum ClientType {
  AFFILIATED = "AFFILIATED",
  ASSOCIATE = "ASSOCIATE",
  INDIVIDUAL = "INDIVIDUAL",
  OTHER = "OTHER",
  PARTNER = "PARTNER",
  PARTNER_AND_UNITY_APP_CLIENT = "PARTNER_AND_UNITY_APP_CLIENT",
  PARTNER_APP_CLIENT = "PARTNER_APP_CLIENT",
  UNITY_APP_CLIENT = "UNITY_APP_CLIENT",
}

export enum ContentRestriction {
  AUTHENTICATED = "AUTHENTICATED",
  FREE_FOR_APPS_ONLY = "FREE_FOR_APPS_ONLY",
  NONE = "NONE",
  OWNER = "OWNER",
  PAID_USER = "PAID_USER",
}

export enum CurrencyCode {
  AUD = "AUD",
  BRL = "BRL",
  CAD = "CAD",
  CHF = "CHF",
  CNY = "CNY",
  CZK = "CZK",
  DKK = "DKK",
  EUR = "EUR",
  GBP = "GBP",
  HKD = "HKD",
  HUF = "HUF",
  ILS = "ILS",
  JPY = "JPY",
  MXN = "MXN",
  MYR = "MYR",
  NOK = "NOK",
  NZD = "NZD",
  PHP = "PHP",
  PLN = "PLN",
  RUB = "RUB",
  SEK = "SEK",
  SGD = "SGD",
  THB = "THB",
  TWD = "TWD",
  USD = "USD",
}

export enum DiscoveryI18nInfoField {
  caption = "caption",
  description = "description",
  subtitle = "subtitle",
  title = "title",
}

export enum DiscoveryImageType {
  INFOGRAPHIC = "INFOGRAPHIC",
  NON_INFOGRAPHIC = "NON_INFOGRAPHIC",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  UNSPECIFIED = "UNSPECIFIED",
}

export enum GeoJSONFileType {
  BOUNDARY = "BOUNDARY",
  BUILDINGS = "BUILDINGS",
  EXTERNAL_WALLS = "EXTERNAL_WALLS",
  INTERNAL_WALLS = "INTERNAL_WALLS",
  PARK = "PARK",
  ROADS = "ROADS",
  SITE_AREA = "SITE_AREA",
  TRAIL = "TRAIL",
  WATER = "WATER",
}

export enum GeoJSONType {
  LineString = "LineString",
  MultiLineString = "MultiLineString",
  MultiPoint = "MultiPoint",
  MultiPolygon = "MultiPolygon",
  Point = "Point",
  Polygon = "Polygon",
}

export enum I18nInfoField {
  description = "description",
  descriptionLong = "descriptionLong",
  name = "name",
  tagline = "tagline",
}

export enum InvoiceStatus {
  DISPUTED = "DISPUTED",
  FINALISED = "FINALISED",
  ISSUED = "ISSUED",
  PROCESSING = "PROCESSING",
}

export enum LanguageCode {
  ar = "ar",
  da_DK = "da_DK",
  de_DE = "de_DE",
  el_GR = "el_GR",
  en_AU = "en_AU",
  en_GB = "en_GB",
  en_US = "en_US",
  es_ES = "es_ES",
  fr_CA = "fr_CA",
  fr_FR = "fr_FR",
  he_IL = "he_IL",
  hi_IN = "hi_IN",
  it_IT = "it_IT",
  ja_JP = "ja_JP",
  ko_KR = "ko_KR",
  nb_NO = "nb_NO",
  nl_NL = "nl_NL",
  pl_PL = "pl_PL",
  pt_BR = "pt_BR",
  pt_PT = "pt_PT",
  ru_RU = "ru_RU",
  sk_SK = "sk_SK",
  sv_SE = "sv_SE",
  tl_PH = "tl_PH",
  tr_TR = "tr_TR",
  uk_UA = "uk_UA",
  zh = "zh",
}

export enum NarrationSource {
  GOOGLE_CLOUD_TEXT_TO_SPEECH = "GOOGLE_CLOUD_TEXT_TO_SPEECH",
  PROFESSIONALLY_RECORDED = "PROFESSIONALLY_RECORDED",
}

export enum PaymentGateway {
  NONE = "NONE",
  PAYPAL = "PAYPAL",
  STRIPE = "STRIPE",
}

export enum PlaceFeature {
  COMPASS_ORIENTATION = "COMPASS_ORIENTATION",
  CUSTOM_MAP = "CUSTOM_MAP",
  GOOGLE_MAP = "GOOGLE_MAP",
  SPLIT_SCREEN = "SPLIT_SCREEN",
  UNNUMBERED_VIEWPOINTS = "UNNUMBERED_VIEWPOINTS",
  USER_POSITION = "USER_POSITION",
  VIEWPOINT_PROXIMITY_SENSING = "VIEWPOINT_PROXIMITY_SENSING",
}

export enum PlaceType {
  ANCIENT_WORLD = "ANCIENT_WORLD",
  EXPEDITION = "EXPEDITION",
}

export enum PlatformType {
  ANDROID = "ANDROID",
  IOS = "IOS",
  WEB = "WEB",
}

export enum PlaylistFeature {
  COMPASS_ORIENTATION = "COMPASS_ORIENTATION",
  CUSTOM_MAP = "CUSTOM_MAP",
  GOOGLE_MAP = "GOOGLE_MAP",
  SPLIT_SCREEN = "SPLIT_SCREEN",
  UNNUMBERED_VIEWPOINTS = "UNNUMBERED_VIEWPOINTS",
  USER_POSITION = "USER_POSITION",
  VIEWPOINT_PROXIMITY_SENSING = "VIEWPOINT_PROXIMITY_SENSING",
}

export enum ProductSKU {
  GIFT_CARD = "GIFT_CARD",
  PLAYLIST = "PLAYLIST",
  PLAYLIST_BUNDLE = "PLAYLIST_BUNDLE",
  VIEWPOINT = "VIEWPOINT",
}

export enum PromoCodeType {
  PERCENTAGE_ON_PURCHASE_PRICE = "PERCENTAGE_ON_PURCHASE_PRICE",
}

export enum PublishedState {
  BOTH_PUBLISHED_AND_UNPUBLISHED = "BOTH_PUBLISHED_AND_UNPUBLISHED",
  PUBLISHED_ONLY = "PUBLISHED_ONLY",
  UNPUBLISHED_ONLY = "UNPUBLISHED_ONLY",
}

export enum PurchaseMode {
  GIFT = "GIFT",
  PERSONAL = "PERSONAL",
}

export enum PurchaseOwnerType {
  CLIENT = "CLIENT",
  CONSUMER = "CONSUMER",
}

export enum RedeemPurchaseMode {
  BUY = "BUY",
  ORDER = "ORDER",
}

export enum Role {
  ADMIN = "ADMIN",
  AW_ANONYMOUS_USER = "AW_ANONYMOUS_USER",
  CLIENT_ADMIN = "CLIENT_ADMIN",
  CLIENT_USER = "CLIENT_USER",
  CONSUMER = "CONSUMER",
  CONTINUOUS_INTEGRATION = "CONTINUOUS_INTEGRATION",
  DEMO = "DEMO",
  DEVELOPER = "DEVELOPER",
  EARLY_ACCESS_USER = "EARLY_ACCESS_USER",
  PARTNER = "PARTNER",
  PUBLISHER = "PUBLISHER",
  SALES = "SALES",
  SERVICE = "SERVICE",
  STAFF = "STAFF",
  SUBSCRIBER = "SUBSCRIBER",
  TESTING = "TESTING",
  TOKEN_ACCESS_USER = "TOKEN_ACCESS_USER",
  TOUR_OPERATOR = "TOUR_OPERATOR",
  USER = "USER",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TimePrecision {
  DAY = "DAY",
  HOUR = "HOUR",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export enum TourType {
  ANCIENT_WORLD = "ANCIENT_WORLD",
  EXPEDITION = "EXPEDITION",
}

export enum ValidFrom {
  TIME_OF_CREATION = "TIME_OF_CREATION",
  TIME_OF_FIRST_USE = "TIME_OF_FIRST_USE",
}

export interface AccessOverridesInput {
  productSKU: string;
  productID: string;
}

export interface AnalyticEventInput {
  appID?: string | null;
  appVersion?: string | null;
  data?: (LooseAnalyticsMetadataInput | null)[] | null;
  deviceID?: string | null;
  eventID: string;
  location?: CoordinatesInput | null;
  name: string;
  sessionID?: string | null;
  timestamp: any;
  timezone?: string | null;
  userID?: string | null;
}

export interface ArtefactI18nInfo {
  field: ArtefactI18nInfoField;
  languageCode: LanguageCode;
  value: string;
}

export interface ChangePasswordInput {
  confirmPassword: string;
  password: string;
  resetPasswordSecret?: string | null;
}

export interface ClientAccessSubTypeInput {
  validFrom?: ValidFrom | null;
  validityPeriodMS?: number | null;
  redeemableToursCount?: number | null;
  accessRestriction?: AccessRestriction | null;
  maxDevicesCount?: number | null;
  accessType?: AccessType | null;
}

export interface ClientProductsInput {
  productSKU: ProductSKU;
  productIDs?: string[] | null;
}

export interface CoordinatesInput {
  longitude: number;
  latitude: number;
}

export interface CreateAccessInput {
  accessRestriction?: AccessRestriction | null;
  clientID: string;
  count?: number | null;
  description?: string | null;
  maxDevicesCount?: number | null;
  productSKU?: string | null;
  accessType: AccessType;
  validFrom?: ValidFrom | null;
  validityPeriodMS?: number | null;
  validityPeriodMSForTour?: number | null;
  redeemableBundleIDs?: string[] | null;
  redeemableTourIDs?: string[] | null;
  redeemableToursCount?: number | null;
  country?: string | null;
  accessSubType?: AccessSubType | null;
  guideNumber?: number | null;
  disabledUntil?: any | null;
}

export interface CreateAffiliateCodeInput {
  clientID: string;
  description?: string | null;
  commissionRate?: number | null;
  type?: AffiliateCodetype | null;
}

export interface CreateAppInput {
  appID: string;
  name: string;
  appType: AppType;
  description?: string | null;
  platforms?: (PlatformInput | null)[] | null;
  viewpointIDs?: string[] | null;
  playlistBundleIDs?: string[] | null;
  curatedPlaylistIDs?: string[] | null;
  allowAllAncientWorldTours?: boolean | null;
  siteIDs?: string[] | null;
  releases?: (ReleaseInput | null)[] | null;
  url?: string | null;
  themeAssetID?: string | null;
  styleSheetAssetID?: string | null;
  settingsAssetID?: string | null;
  clientID?: string | null;
  ftDisplayOnlyPurchasedCuratedPlaylists?: boolean | null;
  ftDisplayOnlyPurchasedPlaylistBundles?: boolean | null;
}

export interface CreateArtefactInput {
  stopID: string;
  i18nInfoFields?: ArtefactI18nInfo[] | null;
  internalReference: string;
  artefactType: string;
  artefactModelAssetID?: string | null;
  thumbnailID?: string | null;
}

export interface CreateAssetInput {
  filename: string;
}

export interface CreateClientInput {
  name: string;
  notes?: string | null;
  type?: ClientType | null;
  redeemPurchaseMode?: RedeemPurchaseMode | null;
  businessEmail?: string | null;
  businessPhone?: string | null;
  businessAddress?: string | null;
  countryID?: string | null;
  contactName?: string | null;
  contactEmail?: string | null;
  contactPhone?: string | null;
  email?: string | null;
  password?: string | null;
  appIDs?: (string | null)[] | null;
  wholeSalePricingTable?: WholeSalePricingInput[] | null;
  flatRateDiscounts?: FlatRateDiscountInput[] | null;
  educational?: boolean | null;
  bundleOnly?: boolean | null;
  canOrderCountryBundles?: boolean | null;
  travellerAccessSubType?: ClientAccessSubTypeInput | null;
  guideAccessSubType?: ClientAccessSubTypeInput | null;
  accessOverrides?: AccessOverridesInput[] | null;
  logoImage?: string | null;
  allowSystemAdminToLoginAsClient?: boolean | null;
  ftEnableServerFilteredOrdersPage?: boolean | null;
}

export interface CreateCuratedPlaylistInput {
  countryID?: string | null;
  audioDurationMinutes?: number | null;
  coverID?: string | null;
  featured?: boolean | null;
  features?: PlaylistFeature[] | null;
  guidedTourVideoID?: string | null;
  guideID?: string | null;
  hidden?: boolean | null;
  i18nInfoFields?: I18nInfo[] | null;
  internalReference: string;
  introVideoID?: string | null;
  label?: string | null;
  map?: CreateMapInput | null;
  mapGeoJSONID?: string | null;
  narrationConfig?: NarrationConfigInput | null;
  pastThumbnailID?: string | null;
  presentThumbnailID?: string | null;
  pressReleaseFileID?: string | null;
  price?: PriceInput | null;
  purchaseConfirmationThumbnailID?: string | null;
  tags?: string[] | null;
  thumbnailID?: string | null;
  timePeriod?: string | null;
  trailLength?: number | null;
  validityPeriodMonths?: number | null;
  viewpointIDs?: (string | null)[] | null;
  vrThumbnailID?: string | null;
  stopIDs?: (string | null)[] | null;
  tourType?: TourType | null;
  geofenceID?: string | null;
}

export interface CreateDiscoveryInput {
  thumbnailID?: string | null;
  coverID?: string | null;
  modelID?: string | null;
  imageType?: DiscoveryImageType | null;
  stopID: string;
  location?: CoordinatesInput | null;
  i18nInfoFields?: DiscoveryI18nInfo[] | null;
  internalReference: string;
  discoveryType: string;
  gender?: Gender | null;
  voiceTrackAssetID?: string | null;
  voiceTrackLengthInSeconds?: number | null;
  languageCode?: string | null;
  srts?: CreateDiscoveryNarrationSrtInput[] | null;
}

export interface CreateDiscoveryNarrationInput {
  gender: Gender;
  languageCode: LanguageCode;
  transcript?: string | null;
  voiceTrackAssetID: string;
  voiceTrackLengthInSeconds?: number | null;
  discoveryID: string;
  srtAssetID?: string | null;
  srts?: CreateDiscoveryNarrationSrtInput[] | null;
}

export interface CreateDiscoveryNarrationSrtInput {
  languageCode: LanguageCode;
  srtAssetID: string;
}

export interface CreateGuideInput {
  name?: string | null;
  location?: string | null;
  bio?: string | null;
  avatarID?: string | null;
}

export interface CreateImageInput {
  assetID?: string | null;
  i18nInfoFields?: (I18nInfo | null)[] | null;
  viewpointID: string;
}

export interface CreateInternalGiftPurchaseInput {
  email: string;
  productID: string;
  productSKU: string;
  isClient?: boolean | null;
  count?: number | null;
}

export interface CreateLocationInput {
  name: string;
  internalReference: string;
  multiPolygonGeometry?: MultiPolygonGeometryInput | null;
  polygonGeometry?: PolygonGeometryInput | null;
}

export interface CreateMapInput {
  img: string;
  width: number;
  height: number;
  projectionCode: string;
  extXMin: number;
  extYMin: number;
  extXMax: number;
  extYMax: number;
  defaultViewBoxXMin?: number | null;
  defaultViewBoxYMin?: number | null;
  defaultViewBoxXMax?: number | null;
  defaultViewBoxYMax?: number | null;
}

export interface CreateMusicInput {
  assetID: string;
  notes?: string | null;
  title: string;
  length: number;
}

export interface CreateNarrationInput {
  gender: Gender;
  languageCode: LanguageCode;
  transcript?: string | null;
  voiceTrackAssetID: string;
  voiceTrackLengthInSeconds: number;
  viewpointID: string;
  srtAssetID?: string | null;
  srts?: CreateNarrationSrtInput[] | null;
}

export interface CreateNarrationSrtInput {
  languageCode: LanguageCode;
  srtAssetID: string;
}

export interface CreatePlaceInput {
  geofenceID?: string | null;
  i18nInfoFields?: I18nInfo[] | null;
  internalReference: string;
  audioDurationMinutes?: number | null;
  coverID?: string | null;
  features?: PlaceFeature[] | null;
  guideID?: string | null;
  introVideoID?: string | null;
  map?: CreateMapInput | null;
  mapGeoJSONID?: string | null;
  pastThumbnailID?: string | null;
  presentThumbnailID?: string | null;
  pressReleaseFileID?: string | null;
  price?: PriceInput | null;
  tags?: string[] | null;
  thumbnailID?: string | null;
  timePeriod?: string | null;
  trailLength?: number | null;
  validityPeriodMonths?: number | null;
  hidden?: boolean | null;
  purchaseConfirmationThumbnailID?: string | null;
  placeType?: PlaceType | null;
  stopIDs?: (string | null)[] | null;
}

export interface CreatePlaylistIntroNarrationInput {
  gender: Gender;
  languageCode: LanguageCode;
  transcript?: string | null;
  voiceTrackAssetID: string;
  voiceTrackLengthInSeconds: number;
  playlistID: string;
  srts?: CreatePlaylistIntroNarrationSrtInput[] | null;
}

export interface CreatePlaylistIntroNarrationSrtInput {
  languageCode: LanguageCode;
  srtAssetID: string;
}

export interface CreateProjectionInput {
  assetID: string;
  stopID: string;
  originalName: string;
  d1?: number[] | null;
  d2?: number[] | null;
  d3?: number[] | null;
  a1?: number[] | null;
  a2?: number[] | null;
  a3?: number[] | null;
  mobile?: boolean | null;
}

export interface CreatePromoCodeInput {
  code: string;
  expiresAt: any;
  discountRate: number;
  promoCodeType: PromoCodeType;
  educational?: boolean | null;
}

export interface CreateSiteInput {
  countryID?: string | null;
  i18nInfoFields?: I18nInfo[] | null;
  internalReference: string;
  tags?: string[] | null;
  thumbnailID?: string | null;
  vrThumbnailID?: string | null;
}

export interface CreateStopInput {
  geofenceID?: string | null;
  i18nInfoFields?: I18nInfo[] | null;
  internalReference: string;
  countryID?: string | null;
  fallbackGeofenceRadius?: number | null;
  location?: CoordinatesInput | null;
  published?: boolean | null;
  rotation?: Vector3Input | null;
  tags?: string[] | null;
  musicID?: string | null;
  timePeriodID?: string | null;
  timePeriodSpecific?: string | null;
  thumbnailID?: string | null;
  vrThumbnailID?: string | null;
  vpsGeoJSONID?: string | null;
  ownedBy?: string | null;
  restriction?: ContentRestriction | null;
  isExclusive?: boolean | null;
  environment?: string | null;
}

export interface CreateStopNarrationInput {
  gender: Gender;
  languageCode: LanguageCode;
  transcript?: string | null;
  voiceTrackAssetID: string;
  voiceTrackLengthInSeconds?: number | null;
  stopID: string;
  srtAssetID?: string | null;
  srts?: CreateStopNarrationSrtInput[] | null;
}

export interface CreateStopNarrationSrtInput {
  languageCode: LanguageCode;
  srtAssetID: string;
}

export interface CreateUserInput {
  activationDuration?: number | null;
  email: string;
  name: string;
  password: string;
  roles?: (Role | null)[] | null;
  clientID?: string | null;
  affiliateCodeID?: string | null;
}

export interface CreateViewpointInput {
  countryID?: string | null;
  environment?: string | null;
  geofenceID?: string | null;
  fallbackGeofenceRadius?: number | null;
  i18nInfoFields?: I18nInfo[] | null;
  isExclusive?: boolean | null;
  location?: CoordinatesInput | null;
  streetViewLocation?: CoordinatesInput | null;
  streetViewHeading?: number | null;
  ownedBy?: string | null;
  published?: boolean | null;
  restriction?: ContentRestriction | null;
  rotation?: Vector3Input | null;
  internalReference: string;
  tags?: string[] | null;
  closestViewpoints?: string[] | null;
  timePeriodID?: string | null;
  leftProjectionID?: string | null;
  rightProjectionID?: string | null;
  thumbnailID?: string | null;
  vrThumbnailID?: string | null;
  musicID?: string | null;
  stopID?: string | null;
  timePeriodSpecific?: string | null;
}

export interface CreateVpInput {
  internalReference: string;
  internalReferenceIndex?: string | null;
  closestVps?: string[] | null;
  location?: CoordinatesInput | null;
  streetViewLocation?: CoordinatesInput | null;
  streetViewHeading?: number | null;
  startingVp?: boolean | null;
  cameraPosition?: number[] | null;
}

export interface DeleteMusicInput {
  musicID: string;
}

export interface DeleteProjectionsInput {
  projectionNames: string[];
  stopID: string;
}

export interface DiscoveryI18nInfo {
  field: DiscoveryI18nInfoField;
  languageCode: LanguageCode;
  value: string;
}

export interface DurationInput {
  from?: any | null;
  to?: any | null;
}

export interface FlatRateDiscountInput {
  playlistID: string;
  discountRate: number;
}

export interface GenerateAffiliateInvoiceInput {
  clientID: string;
  upToDate?: any | null;
}

export interface GenerateGCSSignedURLInput {
  filename: string;
  action: string;
  contentType: string;
}

export interface GeoJSONFileInput {
  asset: string;
  geoJSONFileType: GeoJSONFileType;
}

export interface GetAccessByIDInput {
  accessID: string;
}

export interface GetAccessCodePurchasesInput {
  clientID?: string | null;
  from?: any | null;
  to?: any | null;
  accessTypes?: AccessType[] | null;
}

export interface GetAccessCodesStatusInput {
  clientID?: string | null;
  from?: any | null;
  to?: any | null;
  accessTypes?: AccessType[] | null;
}

export interface GetActivationCountInput {
  from?: any | null;
  to?: any | null;
  unit?: ActivationCountUnit | null;
}

export interface GetAffiliateCodeByIDInput {
  affiliateCodeID: string;
}

export interface GetAffiliateCodesInput {
  clientID: string;
}

export interface GetAffiliatedPurchasesInput {
  from: any;
  to: any;
  productSKU?: ProductSKU | null;
  clientID?: string | null;
}

export interface GetAllAffiliatePurchasesInput {
  from: any;
  to: any;
}

export interface GetAnalyticEventsByPaginationInput {
  appIDs?: (string | null)[] | null;
  appVersions?: (string | null)[] | null;
  deviceIDs?: (string | null)[] | null;
  eventIDs?: (string | null)[] | null;
  from?: any | null;
  ipAddresses?: (string | null)[] | null;
  names?: (string | null)[] | null;
  sessionIDs?: (string | null)[] | null;
  to?: any | null;
  userIDs?: (string | null)[] | null;
  viewpointIDs?: (string | null)[] | null;
  tourIDs?: (string | null)[] | null;
  skip?: number | null;
  limit?: number | null;
}

export interface GetAnalyticEventsInput {
  appIDs?: (string | null)[] | null;
  appVersions?: (string | null)[] | null;
  deviceIDs?: (string | null)[] | null;
  eventIDs?: (string | null)[] | null;
  from?: any | null;
  ipAddresses?: (string | null)[] | null;
  names?: (string | null)[] | null;
  sessionIDs?: (string | null)[] | null;
  to?: any | null;
  userIDs?: (string | null)[] | null;
  viewpointIDs?: (string | null)[] | null;
  tourIDs?: (string | null)[] | null;
}

export interface GetAncientWorldPopularToursWithPrecisionInput {
  precision: TimePrecision;
  from: any;
  to: any;
}

export interface GetAncientWorldPopularViewpointsWithPrecisionInput {
  precision: TimePrecision;
  from: any;
  to: any;
}

export interface GetAncientWorldTrafficVolumeWithPrecisionInput {
  precision: TimePrecision;
  from: any;
  to: any;
}

export interface GetAncientWorldUserAgeDemographicsWithPrecisionInput {
  precision: TimePrecision;
  from: any;
  to: any;
}

export interface GetAncientWorldUserCountryOfOriginDemographicsWithPrecisionInput {
  precision: TimePrecision;
  from: any;
  to: any;
}

export interface GetAncientWorldUserGenderDemographicsWithPrecisionInput {
  precision: TimePrecision;
  from: any;
  to: any;
}

export interface GetAncientWorldUsersInput {
  from: any;
  to: any;
}

export interface GetAncientWorldViewpointDurationWithPrecisionInput {
  precision: TimePrecision;
  from: any;
  to: any;
}

export interface GetAppSessionReportInput {
  appIDs?: (string | null)[] | null;
  precision?: TimePrecision | null;
  from?: any | null;
  to?: any | null;
}

export interface GetAppTourRatingsInput {
  appID: string;
}

export interface GetAppURLsForOrdersInput {
  accessID: string;
  clientID?: string | null;
}

export interface GetAssetInput {
  assetID: string;
}

export interface GetClientInput {
  clientID: string;
}

export interface GetClientOrdersWithPaginationInput {
  key: string;
  offset?: number | null;
  limit?: number | null;
  skipDistributed?: boolean | null;
  skipDisabled?: boolean | null;
  skipExpired?: boolean | null;
  skipFullyRedeemed?: boolean | null;
  sortBy?: AccessSortField | null;
  sortOrder?: SortOrder | null;
  dateField?: AccessDateField | null;
  startDate?: any | null;
  endDate?: any | null;
}

export interface GetClientPurchasesInput {
  clientID?: string | null;
  from?: any | null;
  products?: ClientProductsInput[] | null;
  to?: any | null;
}

export interface GetClientToursForConsoleInput {
  playlistIDs?: (string | null)[] | null;
  clientID?: string | null;
}

export interface GetClientsInput {
  types?: ClientType[] | null;
  createdAtFrom?: any | null;
  createdAtTo?: any | null;
}

export interface GetCountriesInput {
  _?: boolean | null;
}

export interface GetCountryByIDInput {
  countryID: string;
}

export interface GetCuratedPlaylistsInput {
  label?: string | null;
  publishedState?: PublishedState | null;
  includeFeatured?: boolean | null;
  playlistIDs?: (string | null)[] | null;
}

export interface GetImageByIDInput {
  imageID: string;
}

export interface GetImagesInput {
  viewpointID?: string | null;
}

export interface GetInvoiceByIDInput {
  invoiceID: string;
}

export interface GetInvoicesInput {
  clientIDs?: string[] | null;
}

export interface GetNarrationsInput {
  viewpointID: string;
  gender?: Gender | null;
  languageCodes?: (LanguageCode | null)[] | null;
  narrationSource?: NarrationSource | null;
}

export interface GetNewClientAdminJWTInput {
  clientID: string;
}

export interface GetPlaylistInput {
  playlistID?: string | null;
}

export interface GetPlaylistsByStopIDInput {
  stopID: string;
}

export interface GetPlaylistsByTourTypeAndPublishedStateInput {
  tourType: string;
  publishedState: PublishedState;
}

export interface GetPublicPlaylistInput {
  playlistID: string;
}

export interface GetPurchasesInput {
  affiliateCodeIDs?: (string | null)[] | null;
  from?: any | null;
  paymentGateways?: (PaymentGateway | null)[] | null;
  to?: any | null;
  verified?: boolean | null;
  includeInternal?: boolean | null;
  educational?: boolean | null;
  includeSandbox?: boolean | null;
}

export interface GetSitesInput {
  publishedState?: PublishedState | null;
}

export interface GetStopInput {
  stopID?: string | null;
  internalReference?: string | null;
}

export interface GetTimePeriodByIDInput {
  timePeriodID: string;
}

export interface GetTimePeriodsInput {
  _?: boolean | null;
}

export interface GetTourAnalyticsInput {
  tourID: string;
  from: any;
  to: any;
}

export interface GetUserActivationsInput {
  userIDs?: (string | null)[] | null;
  limit?: number | null;
  skip?: number | null;
  createdBetween?: DurationInput | null;
  includeInternal?: boolean | null;
  issuedBetween?: DurationInput | null;
}

export interface GetUserByEmailInput {
  email: string;
}

export interface GetUsersInput {
  roles: (Role | null)[];
}

export interface GetViewpointInput {
  viewpointID?: string | null;
  internalReference?: string | null;
}

export interface GetViewpointsInput {
  includeViewpointsWithMissingThumbnails?: boolean | null;
  includeViewpointsWithMissingProjections?: boolean | null;
  publishedState?: PublishedState | null;
}

export interface I18nInfo {
  field: I18nInfoField;
  languageCode: LanguageCode;
  value?: string | null;
}

export interface LooseAnalyticsMetadataInput {
  key?: string | null;
  value?: string | null;
}

export interface ModerateReviewInput {
  reviewID: string;
  isApproved: boolean;
}

export interface MultiPolygonGeometryInput {
  type: GeoJSONType;
  coordinates: (((number[] | null)[] | null)[] | null)[];
}

export interface NarrationConfigInput {
  gender?: Gender | null;
  narrationSource?: NarrationSource | null;
  languageCode?: LanguageCode | null;
}

export interface PlatformInput {
  type: PlatformType;
  consoleUrl?: string | null;
  storeUrl?: string | null;
}

export interface PolygonGeometryInput {
  type: GeoJSONType;
  coordinates: ((number[] | null)[] | null)[];
}

export interface PriceInput {
  AUD: number;
  BRL: number;
  CAD: number;
  CNY: number;
  CZK: number;
  DKK: number;
  EUR: number;
  HKD: number;
  HUF: number;
  ILS: number;
  JPY: number;
  MYR: number;
  MXN: number;
  TWD: number;
  NZD: number;
  NOK: number;
  PHP: number;
  PLN: number;
  GBP: number;
  RUB: number;
  SGD: number;
  SEK: number;
  CHF: number;
  THB: number;
  USD: number;
}

export interface ProductInput {
  productSKU?: ProductSKU | null;
  productID?: string | null;
}

export interface ProductWithCountInput {
  productSKU: string;
  productID: string;
  count: number;
}

export interface ReleaseInput {
  type: any;
  releaseNotes?: string | null;
  version: string;
}

export interface SendResetPasswordLinkInput {
  email: string;
  passwordResetURL?: string | null;
  recaptchaToken?: string | null;
}

export interface SetAccessCodeDistributedInput {
  token: string;
  distributed: boolean;
}

export interface SetDiscoveryNarrationInput {
  discoveryID: string;
  narrationID: string;
}

export interface SetGiftPurchaseDistributedInput {
  redeemCode: string;
  distributed: boolean;
}

export interface SetPlaylistIntroNarrationInput {
  playlistID: string;
  narrationID: string;
}

export interface SetStopNarrationInput {
  stopID: string;
  narrationID: string;
}

export interface SetUndistributedAccessCodesAsDistributedInput {
  tokens?: string[] | null;
}

export interface SetUndistributedGiftPurchasesAsDistributedInput {
  notes?: string | null;
  products?: ProductInput[] | null;
}

export interface SetViewpointNarrationInput {
  viewpointID: string;
  narrationID: string;
}

export interface SubmitAnalyticEventsInput {
  events?: (AnalyticEventInput | null)[] | null;
}

export interface SubmitPayPalPurchaseMultiWithCountsAndMultiCurrenciesInput {
  currencyCode: CurrencyCode;
  orderID: string;
  products: (ProductWithCountInput | null)[];
  productURL: string;
  isSandbox: boolean;
  purchaseMode: PurchaseMode;
  ownerType?: PurchaseOwnerType | null;
  promoCodeID?: string | null;
}

export interface UnsetDiscoveryNarrationInput {
  discoveryID: string;
  narrationID: string;
}

export interface UnsetPlaylistIntroNarrationInput {
  playlistID: string;
  narrationID: string;
}

export interface UnsetStopNarrationInput {
  stopID: string;
  narrationID: string;
}

export interface UnsetViewpointNarrationInput {
  viewpointID: string;
  narrationID: string;
}

export interface UpdateAccessNotesInput {
  accessID: string;
  notes?: string | null;
}

export interface UpdateAffiliateCodeInput {
  affiliateCodeID: string;
  description?: string | null;
  commissionRate?: number | null;
  type?: AffiliateCodetype | null;
}

export interface UpdateAppInput {
  id: string;
  allowAllAncientWorldTours?: boolean | null;
  appID?: string | null;
  appType?: AppType | null;
  curatedPlaylistIDs?: string[] | null;
  playlistBundleIDs?: string[] | null;
  description?: string | null;
  name?: string | null;
  platforms?: (PlatformInput | null)[] | null;
  releases?: (ReleaseInput | null)[] | null;
  settingsAssetID?: string | null;
  siteIDs?: string[] | null;
  styleSheetAssetID?: string | null;
  themeAssetID?: string | null;
  url?: string | null;
  viewpointIDs?: string[] | null;
  clientID?: string | null;
  ftDisplayOnlyPurchasedCuratedPlaylists?: boolean | null;
  ftDisplayOnlyPurchasedPlaylistBundles?: boolean | null;
}

export interface UpdateArtefactInput {
  stopID?: string | null;
  artefactType?: string | null;
  location?: CoordinatesInput | null;
  artefactID: string;
  i18nInfoFields?: ArtefactI18nInfo[] | null;
  artefactModelAssetID?: string | null;
  thumbnailID?: string | null;
}

export interface UpdateClientInput {
  addAppIDs?: (string | null)[] | null;
  addUserIDs?: (string | null)[] | null;
  clientID: string;
  disabled?: boolean | null;
  name?: string | null;
  notes?: string | null;
  removeAppIDs?: (string | null)[] | null;
  removeUserIDs?: (string | null)[] | null;
  type?: ClientType | null;
  redeemPurchaseMode?: RedeemPurchaseMode | null;
  travellerAccessSubType?: ClientAccessSubTypeInput | null;
  guideAccessSubType?: ClientAccessSubTypeInput | null;
  countryID?: string | null;
  businessEmail?: string | null;
  businessPhone?: string | null;
  businessAddress?: string | null;
  contactName?: string | null;
  contactEmail?: string | null;
  contactPhone?: string | null;
  educational?: boolean | null;
  bundleOnly?: boolean | null;
  canOrderCountryBundles?: boolean | null;
  wholeSalePricingTable?: WholeSalePricingInput[] | null;
  flatRateDiscounts?: FlatRateDiscountInput[] | null;
  accessOverrides?: AccessOverridesInput[] | null;
  logoImage?: string | null;
  allowSystemAdminToLoginAsClient?: boolean | null;
  ftEnableServerFilteredOrdersPage?: boolean | null;
}

export interface UpdateClientProfileInput {
  businessEmail?: string | null;
  businessPhone?: string | null;
  businessAddress?: string | null;
  contactName?: string | null;
  contactEmail?: string | null;
  contactPhone?: string | null;
  logoImage?: string | null;
  allowSystemAdminToLoginAsClient?: boolean | null;
  ftEnableServerFilteredOrdersPage?: boolean | null;
}

export interface UpdateCuratedPlaylistInput {
  audioDurationMinutes?: number | null;
  countryID?: string | null;
  coverID?: string | null;
  curatedPlaylistID: string;
  featured?: boolean | null;
  features?: PlaylistFeature[] | null;
  guidedTourVideoID?: string | null;
  guideID?: string | null;
  hidden?: boolean | null;
  i18nInfoFields?: I18nInfo[] | null;
  internalReference?: string | null;
  introVideoID?: string | null;
  map?: UpdateMapInput | null;
  mapGeoJSONID?: string | null;
  pastThumbnailID?: string | null;
  presentThumbnailID?: string | null;
  pressReleaseFileID?: string | null;
  price?: PriceInput | null;
  published?: boolean | null;
  purchaseConfirmationThumbnailID?: string | null;
  tags?: string[] | null;
  thumbnailID?: string | null;
  timePeriod?: string | null;
  trailLength?: number | null;
  validityPeriodMonths?: number | null;
  viewpointIDs?: (string | null)[] | null;
  stopIDs?: (string | null)[] | null;
  tourType?: TourType | null;
  geofenceID?: string | null;
}

export interface UpdateDiscoveryInput {
  thumbnailID?: string | null;
  coverID?: string | null;
  modelID?: string | null;
  imageType?: DiscoveryImageType | null;
  stopID?: string | null;
  discoveryType?: string | null;
  location?: CoordinatesInput | null;
  discoveryID: string;
  i18nInfoFields?: DiscoveryI18nInfo[] | null;
  gender?: Gender | null;
  voiceTrackAssetID?: string | null;
  voiceTrackLengthInSeconds?: number | null;
  languageCode?: string | null;
  narrationID?: string | null;
  srts?: UpdateDiscoveryNarrationSrtInput[] | null;
}

export interface UpdateDiscoveryNarrationSrtInput {
  languageCode: LanguageCode;
  srtAssetID?: string | null;
}

export interface UpdateGuideInput {
  guideID: string;
  name?: string | null;
  location?: string | null;
  bio?: string | null;
  avatarID?: string | null;
}

export interface UpdateImageInput {
  imageID: string;
  assetID?: string | null;
  i18nInfoFields?: (I18nInfo | null)[] | null;
}

export interface UpdateMapInput {
  projectionCode: string;
  extXMin: number;
  extYMin: number;
  extXMax: number;
  extYMax: number;
  geoJSONFiles: GeoJSONFileInput[];
  defaultViewBoxXMin?: number | null;
  defaultViewBoxYMin?: number | null;
  defaultViewBoxXMax?: number | null;
  defaultViewBoxYMax?: number | null;
}

export interface UpdateMusicInput {
  musicID: string;
  assetID?: string | null;
  notes?: string | null;
  title?: string | null;
  length?: number | null;
}

export interface UpdateNarrationInput {
  gender?: Gender | null;
  languageCode?: LanguageCode | null;
  narrationID: string;
  transcript?: string | null;
  voiceTrackAssetID?: string | null;
  voiceTrackLengthInSeconds?: number | null;
  srts?: UpdateNarrationSrtInput[] | null;
}

export interface UpdateNarrationSrtInput {
  languageCode: LanguageCode;
  srtAssetID?: string | null;
}

export interface UpdatePlaceInput {
  placeID: string;
  geofenceID?: string | null;
  i18nInfoFields?: I18nInfo[] | null;
  internalReference?: string | null;
  audioDurationMinutes?: number | null;
  coverID?: string | null;
  features?: PlaceFeature[] | null;
  guideID?: string | null;
  introVideoID?: string | null;
  map?: UpdateMapInput | null;
  mapGeoJSONID?: string | null;
  pastThumbnailID?: string | null;
  presentThumbnailID?: string | null;
  pressReleaseFileID?: string | null;
  price?: PriceInput | null;
  tags?: string[] | null;
  thumbnailID?: string | null;
  timePeriod?: string | null;
  trailLength?: number | null;
  validityPeriodMonths?: number | null;
  hidden?: boolean | null;
  purchaseConfirmationThumbnailID?: string | null;
  placeType?: PlaceType | null;
  published?: boolean | null;
  stopIDs?: (string | null)[] | null;
}

export interface UpdateProfileInput {
  name?: string | null;
}

export interface UpdateProjectionInput {
  projectionID: string;
  originalName?: string | null;
  d1?: number[] | null;
  d2?: number[] | null;
  d3?: number[] | null;
  a1?: number[] | null;
  a2?: number[] | null;
  a3?: number[] | null;
  portalStops?: any | null;
}

export interface UpdatePurchaseNotesInput {
  redeemCode: string;
  notes?: string | null;
}

export interface UpdateSiteInput {
  countryID?: string | null;
  i18nInfoFields?: I18nInfo[] | null;
  published?: boolean | null;
  siteID: string;
  tags?: string[] | null;
  thumbnailID?: string | null;
}

export interface UpdateStopInput {
  stopID: string;
  geofenceID?: string | null;
  i18nInfoFields?: I18nInfo[] | null;
  internalReference?: string | null;
  countryID?: string | null;
  fallbackGeofenceRadius?: number | null;
  location?: CoordinatesInput | null;
  published?: boolean | null;
  rotation?: Vector3Input | null;
  tags?: string[] | null;
  musicID?: string | null;
  timePeriodID?: string | null;
  timePeriodSpecific?: string | null;
  thumbnailID?: string | null;
  vrThumbnailID?: string | null;
  vpsGeoJSONID?: string | null;
  projectionIDs?: string[] | null;
  vps?: CreateVpInput[] | null;
}

export interface UpdateUserInput {
  activationDuration?: number | null;
  addRoles?: Role[] | null;
  clientID?: string | null;
  disabled?: boolean | null;
  email?: string | null;
  name?: string | null;
  password?: string | null;
  removeRoles?: Role[] | null;
  roles?: Role[] | null;
  userID: string;
  visitedPlaylistsDiscoveries?: any | null;
}

export interface UpdateViewpointImagesInput {
  imageIDs: (string | null)[];
  viewpointID: string;
}

export interface UpdateViewpointInput {
  countryID?: string | null;
  environment?: string | null;
  geofenceID?: string | null;
  fallbackGeofenceRadius?: number | null;
  i18nInfoFields?: (I18nInfo | null)[] | null;
  isExclusive?: boolean | null;
  location?: CoordinatesInput | null;
  streetViewLocation?: CoordinatesInput | null;
  streetViewHeading?: number | null;
  ownedBy?: string | null;
  published?: boolean | null;
  restriction?: ContentRestriction | null;
  rotation?: Vector3Input | null;
  viewpointID: string;
  tags?: string[] | null;
  closestViewpoints?: string[] | null;
  timePeriodID?: string | null;
  thumbnailID?: string | null;
  vrThumbnailID?: string | null;
  leftProjectionID?: string | null;
  rightProjectionID?: string | null;
  musicID?: string | null;
  stopID?: string | null;
  timePeriodSpecific?: string | null;
}

export interface Vector3Input {
  x: number;
  y: number;
  z: number;
}

export interface WholeSalePricingInput {
  minQty: number;
  maxQty: number;
  discountRate: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
